<template>
  <div class="main-container">
    <HeaderComponent/>

    <section id="team" class="team-section">
      <h2>Meet the Team!</h2>
      
      <!-- Cast Members Section -->
      <div class="team-group1">
        <h3>Cast Members</h3>
        <div class="team-grid">
          <div class="team-member" v-for="cast in cast" :key="cast.name">
            <a :href="cast.instagram" target="_blank">
              <img :src="cast.image" :alt="`${cast.name} - ${cast.role}`">
            </a>
            <h3>{{ cast.name }}</h3>
            <p>{{ cast.role }}</p>
          </div>
        </div>
      </div>

      <!-- Middle Section with Three Columns -->
      <div class="team-group2">
        <div class="team-section2">
          <div class="team-subgroup">
            <h4>Production Team</h4>
            <div class="team-grid">
              <div class="team-member" v-for="producer in producers" :key="producer.name">
                <a :href="producer.instagram" target="_blank">
                  <img :src="producer.image" :alt="`${producer.name} - ${producer.role}`">
                </a>
                <h3>{{ producer.name }}</h3>
                <p>{{ producer.role }}</p>
              </div>
            </div>
          </div>
          <div class="team-subgroup">
            <h4>Directing Team</h4>
            <div class="team-grid">
              <div class="team-member" v-for="director in directors" :key="director.name">
                <a :href="director.instagram" target="_blank">
                  <img :src="director.image" :alt="`${director.name} - ${director.role}`">
                </a>
                <h3>{{ director.name }}</h3>
                <p>{{ director.role }}</p>
              </div>
            </div>
          </div>
          
        </div>

        <div class="team-subgroup">
            <h4>Camera & Lighting Team</h4>
            <div class="team-grid">
              <div class="team-member" v-for="cameraMember in camera" :key="cameraMember.name">
                <a :href="cameraMember.instagram" target="_blank">
                  <img :src="cameraMember.image" :alt="`${cameraMember.name} - ${cameraMember.role}`">
                </a>
                <h3>{{ cameraMember.name }}</h3>
                <p>{{ cameraMember.role }}</p>
              </div>
            </div>
          </div>
      </div>

      <!-- Third Row with Two Columns -->
      <div class="team-group3">
        <div class="team-section3">
          <div class="team-subgrouppost">
            <h4>Post Production Team</h4>
            <div class="team-grid">
              <div class="team-member" v-for="postMember in post" :key="postMember.name">
                <a :href="postMember.instagram" target="_blank">
                  <img :src="postMember.image" :alt="`${postMember.name} - ${postMember.role}`">
                </a>
                <h3>{{ postMember.name }}</h3>
                <p>{{ postMember.role }}</p>
              </div>
            </div>
            <div class="team-grid">
              <div class="team-member" v-for="postMember in colourists" :key="postMember.name">
                <a :href="postMember.instagram" target="_blank">
                  <img :src="postMember.image" :alt="`${postMember.name} - ${postMember.role}`">
                </a>
                <h3>{{ postMember.name }}</h3>
                <p>{{ postMember.role }}</p>
              </div>
            </div>
          </div>
          
          
        </div>  
        
        <div class="team-subgroup">
            <h4>Art Department Team</h4>
            <div class="team-grid">
              <div class="team-member" v-for="artMember in art" :key="artMember.name">
                <a :href="artMember.instagram" target="_blank">
                  <img :src="artMember.image" :alt="`${artMember.name} - ${artMember.role}`">
                </a>
                <h3>{{ artMember.name }}</h3>
                <p>{{ artMember.role }}</p>
              </div>
            </div>
          </div>
        <div class="team-subgroup">
        <h3>Behind the Scenes Team</h3>
        <div class="team-grid">
          <div class="team-member" v-for="cast in rest" :key="cast.name">
            <a :href="cast.instagram" target="_blank">
              <img :src="cast.image" :alt="`${cast.name} - ${cast.role}`">
            </a>
            <h3>{{ cast.name }}</h3>
            <p>{{ cast.role }}</p>
          </div>
        </div>
      </div>
      </div>
      
      <section id="contact" class="contact-section">
      <h2>Contact Us</h2>
      <p>Email: <a href="mailto:example@email.com">fatamorgana24afda@email.com</a></p>
      <p>Instagram: <a href="https://www.instagram.com/fatamorgana_afda?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank">@fatamorgana_afda</a></p>
    </section>
    </section>
  </div>
</template>


<script>
import Header from './Header.vue';

export default {
  name: 'TeamPage',
  components: {
    HeaderComponent: Header,
  },
  data() {
    return {
      cast: [
        { name: 'Lindiwe Radebe', role: 'Actress - Evelyn', image: 'https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/MeetTheTeam%2F13.%20Lindiwe%20(Actress%20-%20Evelyn).jpg?alt=media&token=44b24194-f1b9-426b-a35d-4aed5811b70f', instagram: 'https://www.instagram.com/thv_lindiwe?igsh=MWZiem9pbmEzbzNuaw==' },
        { name: 'Rebone Mokgadi', role: 'Actress - Willow', image: 'https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/MeetTheTeam%2F14.%20Rebone%20(Actress%20-%20Willow).jpg?alt=media&token=67d5d318-ec84-49b9-a50a-a37e82d7037f  ', instagram: 'https://www.instagram.com/vicky.rsa?igsh=dXI2djlsMXpicGc4' }
      ],
      producers: [
        { name: 'Elvin Quan', 
        role: 'Producer',
        image: 'https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/MeetTheTeam%2F1.%20Elvin%20(Producer).jpg?alt=media&token=0096b3e0-21e8-47df-ae6c-9cb07d386926',
        instagram: 'https://www.instagram.com/elvquan__/profilecard', },
        {name: 'Sabrina Kroft', role: 'Assistant Producer', image: 'https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/MeetTheTeam%2F15.%20Sabrina%20(Assistant%20Producer).jpg?alt=media&token=ecf5a47b-8e5c-4465-ac4a-2061c06324bf', instagram: 'https://www.instagram.com/sabrina_kloft/profilecard/?igsh=MTJqenQ4OTRhOG15MA=='},
      ],
      directors: [
        { name: '⁠Codey Ashton', role: 'Co-Director', image: 'https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/MeetTheTeam%2F2.%20Codey%20(Director).jpg?alt=media&token=e1c8481b-a8b5-4c9f-974c-248570242969', instagram: 'https://www.instagram.com/codey_ashton?igsh=NnNqeDJqc2JvZGFs' },
        { name: '⁠Kariska Kotze', role: 'Co-Director & Writer', image: 'https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/MeetTheTeam%2F3.%20Kariska%20(Director).jpg?alt=media&token=3ffe9487-f657-49bf-ade3-ec26f1953eb6', instagram:'https://www.instagram.com/kariska_kotze?igsh=MXJjYTgybDlyb2JkaA==' }
      ],
      camera: [
        { name: '⁠Palesa Moloeli', role: 'Director of Photography', image: 'https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/MeetTheTeam%2F4.%20Palesa%20(DOP).jpg?alt=media&token=041432ca-c843-4b4b-8f3b-dc1a1a5ff0e8', instagram: 'https://www.instagram.com/palesa_pm?igsh=cW56OTg1N3ZybjEx' },
        { name: 'Olivia Fillmore', role: 'First AC', image: 'https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/MeetTheTeam%2F5.%20Olivia%20(First%20AC).jpg?alt=media&token=d7351a0f-f71a-49c5-8172-b95b2130cd4b', instagram: 'https://www.instagram.com/oliviafaye_photography?igsh=YXRxMzQyNmV3OGN0' },
        {name: '⁠Timothy Jordan Erasmus', role: 'Cinematography Assistant', image: 'https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/MeetTheTeam%2F18.%20Timothy%20Jordan%20(Cine%20Assist).jpg?alt=media&token=1c3899c9-dc98-43da-bfca-86f36e139bec', instagram: 'https://www.instagram.com/tj.eras?igsh=MWFjcGN6c2xxOTY3eQ=='},
        {name: '⁠Ajay Kala', role: 'Cinematography Assistant', image: 'https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/MeetTheTeam%2F19.%20Ajay%20(Cine%20Assist).jpg?alt=media&token=ae26ab37-6059-4d22-89e7-d12cd3fbd92f', instagram: 'https://www.instagram.com/the_cine_colorist?igsh=MTIxOW9vZzR3a2Jtdw=='},
       
      ],
      post: [
        { name: 'Sean Coetzee', role: 'Sound Designer', image: 'https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/MeetTheTeam%2F6.%20Sean%20(Sound%20Designer).jpg?alt=media&token=14adc838-e6e7-48c6-9735-0bf1abe47ec7', instagram: 'https://www.instagram.com/sean_coetzee6?igsh=MTBsMDEyaXhsejhyNA==' },
        { name: 'Wonga Mapumulo', role: 'Music Composer', image: 'https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/MeetTheTeam%2F7.%20Wonga%20(Music%20Composer).jpg?alt=media&token=a3c7ea5e-6d4d-4091-84de-34ad1d5c2ca6', instagram: 'https://www.instagram.com/werxiion?igsh=ZjE5aDh6amoyYWI4' },
        { name: 'Inshirah Aubdool', role: 'Editor & Visual Effects Artist', image: 'https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/MeetTheTeam%2F8.%20Inshirah%20(Editor%20%26%20VFX%20Artist).jpg?alt=media&token=48847157-bf1e-4725-8dbe-2948954e1718',instagram: 'https://www.instagram.com/shirahhsadventure?igsh=aTIxbTliZGJqazdw' },
      ],
      colourists: [
      { name: 'Anton Kotze', role: 'Colourist & Data Wrangler', image: 'https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/MeetTheTeam%2F11.%20Anton%20(Colourist%20%26%20DIT.jpg?alt=media&token=c636ff46-cb97-411b-b742-fe77c0f32d01', instagram: 'https://www.instagram.com/peanut.kotze?igsh=MWgyZ2ZpdmVyeG5iMw=='},
        { name: 'Liam Stockigt', role: 'Colourist', image: 'https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/MeetTheTeam%2F12.%20Liam%20(Colourist).jpg?alt=media&token=41a61a9e-4a30-4a80-b34c-1a257c47a662',instagram: 'https://www.instagram.com/liam_stockigt_?igsh=MW1zbDd1ZzJlbGU3NQ==' }
      
      ],
      art: [
        { name: 'Leilah Darries', role: 'Costume Designer & Hair and Makeup Stylist', image: 'https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/MeetTheTeam%2F9.%20Leilah%20(Character%20Designer%20%26%20Stylist).jpg?alt=media&token=8496e38b-64a2-4f26-ac02-3a0b306539ee', instagram: 'https://www.instagram.com/l3ilxh.d?igsh=dGN1emdleDR6enUy' },
        { name: 'Bernard Malan van Heerdan', role: 'Production Designer', image: 'https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/MeetTheTeam%2F10.%20Malan%20(Production%20Designer).jpg?alt=media&token=222a2b1f-cb75-424b-8314-6783239947a2', instagram: 'https://www.instagram.com/malanvhe?igsh=OWtwbHJ3aHBtOGh2' },
        {name: 'Wafeeq Majiet', role: 'Production Design Assistant', image: 'https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/MeetTheTeam%2F20.%20Wafeeq%20(Production%20Design%20Assist).jpg?alt=media&token=c511dfc6-3cc2-442c-b91b-56bb218abdda', instagram: 'https://www.instagram.com/_feeqy_?igsh=cmFmMDczMTJkMnJq'},
        ],
      rest:[
        
         {name: 'Azeem Rostom', role: 'BTS Videographer & Photographer', image: 'https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/MeetTheTeam%2F16.%20Azeem%20(BTS%20Videographer%20%26%20Photographer).jpg?alt=media&token=dd475f83-99d3-45c4-be2d-cefc4ffef2d3', instagram: 'https://www.instagram.com/azeemrostom?igsh=emRmZWNpMTFrdmsw'},
        {name: '⁠Taylin Thomas', role: 'BTS Videographer & Photographer', image: 'https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/MeetTheTeam%2F17.%20Taylin%20(BTS%20Photographer%20%26%20Videographer).jpg?alt=media&token=7bb47c4f-a82b-457d-9b2c-b8ed96ee991f', instagram: 'https://www.instagram.com/taylinthomas?igsh=MWdncGh5azZxOTFjcA=='},
      ]
    };
  }
};
</script>
<style scoped>
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.main-container{
  
  background: linear-gradient(to bottom, #2b251b, #262118);
  min-height: 100vh;
}
.team-member {
  animation: fadeIn 0.5s ease-in-out;
}

.team-section {
  text-align: center;
  padding-right: 5%;
  padding-left: 5%;
  padding-bottom: 1%;
  background-color: #2b251b;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 40px; /* Increased gap for better spacing */
  padding: 20px 0;
}
.team-grid img {
  margin: auto;
  display: block;
  padding-bottom: 10px;
}

.team-section2, .team-section3 {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.team-subgroup {
  flex: 1;
  background-color: #3d3120;
  padding: 20px;
  margin: 20px 10px; /* Add horizontal and vertical margin */
  border: 1px solid #d4c59e;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  background: linear-gradient(135deg, #3d3120, #4a3a25);
  border-radius: 10px;
}
.team-subgrouppost{
  flex: 1;
  background-color: #3d3120;
  padding: 20px;
  margin: 20px 10px; /* Add horizontal and vertical margin */
  border: 1px solid #d4c59e;
  border-radius: 10px;
}
.team-group1{
  background-color: #433522;
}
.team-group2, .team-group3{
  background-color: #30271a;
}
.team-group1, .team-group2, .team-group3 {
  padding: 2%;
  margin-bottom: 50px;
  border: 1px solid #d4c59e;
}
.team-section h2 {
  font-size: 40px;
  padding-bottom: 10px;
  margin-bottom: 30px;
}

.team-group1 h3, .team-group2 h3, .team-group3 h3 {
  color: #e2e2e6;
  margin-bottom: 30px;
  font-size: 2rem;
}

.team-subgroup h4, .team-subgrouppost h4 {
  font-size: 1.6rem;
  padding-bottom: 15px;
}

.team-member {
  text-align: center;
  animation: fadeIn 0.5s ease-in-out;
}

.team-member img {
  width: 180px; /* Slightly increased for better visuals */
  height: 180px;
  border-radius: 50%;
  object-fit: cover;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  object-position: 50% 20%;
}
.team-member h3 {
  font-size: 1.2rem;
  font-weight: bold;
}
.team-member p {
  font-size: 1rem;
  color: #e2e2e6;
}

.team-member img:hover {
  transform: scale(1.1); /* More noticeable zoom on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); /* More prominent shadow */
}
h2, h3, h4 {
  color: #e2e2e6;
}
.contact-section {
  color: #d4c59e; /* Soft golden text color */
  text-align: center;
  padding: 50px;
}

.contact-section a {
  color: #d4c59e; /* Link color */
  text-decoration: none;
}

.contact-section a:hover {
  color: #e5e5e5; /* Lighter color on hover */
  text-decoration: underline;
}
@media (max-width: 768px) {
  .team-grid {
  display: grid;
  grid-template-columns: 1fr; /* Enforces one column (one person per row) */
  gap: 40px; /* Adjust spacing between team members */
  padding: 20px 0;
}
.team-section2, .team-section3 {
  display: flex;
  flex-direction: column; /* Makes the subgroups stack vertically */
  gap: 20px;
}
}
</style>