<template>
  <div class="main-container">
    <HeaderComponent />
    <section id="bts" class="videos-section">
      <h2>Watch Our Behind The Scenes!</h2>
      <div class="video-layout">
        <!-- Main Video -->
        <div class="main-video">
          <video ref="mainVideo" controls :style="videoStyles" preload="auto">
            <source :src="currentVideo" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
<div class="pad-Buttons">
        <!-- Video Selector Buttons -->
        <div class="other-videos">
          <button @click="changeVideo('https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/BTS%2FFM%20BTS%20DAY%201.mp4?alt=media&token=d4e0739c-6616-446e-91bc-7b10c0796501')" class="video-button">
            Day 1
          </button>
          <button @click="changeVideo('https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/BTS%2FFM%20BTS%20DAY%202.mp4?alt=media&token=6b1fec6c-7b53-41b6-9833-ae734ca3f94d')" class="video-button">
            Day 2
          </button>
          <button @click="changeVideo('https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/BTS%2FFM%20BTS%20DAY%203.mp4?alt=media&token=f0d1cd37-f1f4-410e-b9d3-bbe2d1e93ef5')" class="video-button">
            Day 3
          </button>
          <button @click="changeVideo('https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/BTS%2FFata%20Morgana%20reel%201%20-%20Taylin.mp4?alt=media&token=222cac53-54f6-4dc0-8de2-e61786f85ef1')" class="video-button">
            Reel 1
          </button>
          <button @click="changeVideo('https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/BTS%2FFata%20Morgana%20reel%202%20-%20Taylin.mp4?alt=media&token=77610e91-1330-43be-9f0d-af71f39f8512')" class="video-button">
            Reel 2
          </button>
          <button @click="changeVideo('https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/BTS%2FFata%20Morgana%20reel%203%20-%20Taylin.mp4?alt=media&token=6671337b-4f96-4265-a913-16bfcfba6c36')" class="video-button">
            Reel 3
          </button>
          <button @click="changeVideo('https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/BTS%2FFata%20Morgana%20reel%204%20-%20Taylin.mp4?alt=media&token=5be31ad6-5391-49c6-b7e4-6b0d6c045ae1')" class="video-button">
            Reel 4
          </button>
          <button @click="changeVideo('https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/BTS%2FFM%20MEETING%20reel.mp4?alt=media&token=be51b71e-4771-4ec4-a8da-07ac45e935ec')" class="video-button">
            FM MEETING reel
          </button>
          <button @click="changeVideo('https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/BTS%2FFM%20Photoshoot%20Timelapse.mp4?alt=media&token=ea601d49-8db2-47cb-81c2-e1f3721558ec')" class="video-button">
            FM Photoshoot Timelapse
          </button>
          <button @click="changeVideo('https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/BTS%2FFM%20TEST%20SHOOT%20wide.mp4?alt=media&token=a25ceda9-7dbd-4d4b-b15a-0e977295e6f2')" class="video-button">
            FM TEST SHOOT
          </button>
          
        </div>
      </div>
      </div>
      <br/>
      <br/>
    </section>
  </div>
</template>

<script>
import Header from './Header.vue';

export default {
  name: 'BTSPage',
  components: {
    HeaderComponent: Header,
  },
  data() {
    return {
      currentVideo: 'https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/BTS%2FFM%20MEETING%20reel.mp4?alt=media&token=be51b71e-4771-4ec4-a8da-07ac45e935ec', // Default video
      videoStyles: {
        width: '100%',
        height: '64.4vh', // Set auto height to maintain aspect ratio
        backgroundColor: '#000',
      },
    };
  },
  methods: {
    changeVideo(videoUrl) {
      this.currentVideo = videoUrl;
      this.$refs.mainVideo.load();
    },
  },
};
</script>

<style scoped>
.main-container {
  min-height: 100vh;
  background: linear-gradient(to bottom, #2c2e24, #1f1f1b);
}
/* Section Styles */
.videos-section {
  text-align: center;
  color: #e2d4b7;

  padding-left: 2.5%;
  padding-right: 5%;
}

.video-layout {
  
  display: grid;
  background-color: #2a2c20;
  border-radius: 10px; /* Rounded corners for smoother design */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3); /* Add subtle shadow */
  padding: 1.5%;
  grid-template-columns: 3fr 1fr;
  gap: 30px;
  margin-top: 20px;
}

.main-video {
  background-color: #2a2c20;
  padding: 20px;
  border-radius: 10px; 
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  
}

.other-videos {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 70vh; /* Set a fixed height, adjust as needed */
  overflow-y: auto; /* Enable vertical scroll */
  overflow-x: hidden;
}

.video-button {
  background-color: #2a2c20;
  color: #e2e2e6;
  padding: 15px;
  font-size: 1.1rem; /* Increase font size for better readability */
  border-radius: 10px;
  cursor: pointer;
  text-align: left;
  transition: background-color 0.3s ease;
}

.video-button:hover {
  transform: scale(1.025);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
  color: #e2d4b7;
}

h2 {
  color: #e2e2e6;
  font-size: 36px; /* Increase the font size */
  margin-bottom: 30px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .video-layout {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .main-video {
    padding: 15px;
  }

  .video-button {
    font-size: 1rem;
    padding: 10px;
    background-color: #2a2c20;
  color: #e2e2e6;
  border-radius: 10px;
  cursor: pointer;
  text-align: left;
  transition: background-color 0.3s ease;
  }
  .pad-Buttons{
     padding-top: 5%;
     padding-bottom: 5%;
  }
}
</style>
