<template>
  <div class="main-container">
    <HeaderComponent />

    <section id="sponsors" class="sponsors-section">
      <h2>Meet Our Sponsors:</h2>
      <div class="sponsor-list">
        <div 
          class="sponsor" 
          v-for="sponsor in sponsors" 
          :key="sponsor.name" 
          :class="{'landscape': sponsor.name === 'Walkin21', 'portrait': sponsor.name !== 'Walkin21'}">
          <img :src="sponsor.logo" :alt="`${sponsor.name} Logo`" class="sponsor-logo"/>
          <h3>{{ sponsor.name }}</h3>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Header from './Header.vue';

export default {
  name: 'SponsorsPage',
  components: {
    HeaderComponent: Header,
  },
  data() {
    return {
      sponsors: [
        { name: 'Picardie', logo: require('@/assets/sponsors/Picardie1.png') },
        { name: 'Walkin21', logo: require('@/assets/sponsors/Walkin21_3.png') }, // Landscape sponsor
        { name: 'One More Nap', logo: require('@/assets/sponsors/OneMoreNap2.png') },
      ],
    };
  },
};
</script>

<style scoped>
.main-container {
  min-height: 100vh;
  background: linear-gradient(to bottom, #3f3727, #2f291d);
}
.sponsors-section {
  text-align: center;
  padding-left: 5%;
  padding-right: 5%;
}

.sponsors-section h2 {
  color: #e2e2e6;
  font-size: 2.5rem;
  margin-bottom: 30px;
}

.sponsor-list {
  max-width: 100vw;
  border: 1px solid #d4c59e;
  border-radius: 10px;
  margin: 10px;
  padding-top: 5%;
  padding-bottom: 5%; 
  background-color: #383023;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); /* Responsive grid */
  gap: 20px;
  justify-items: center;
}

.sponsor {
  background-color: #3d3626;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid #d4c59e;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sponsor:hover {
  transform: scale(1.05); /* Keep hover effect for interaction */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Subtle shadow on hover */
}

.sponsor-logo {
  margin-bottom: 20px;
  height: auto;
}

/* Portrait Style (default) */
.portrait {
  width: 250px;
  height: 350px;
}

.portrait .sponsor-logo {
  max-height: 150px;
  width: auto;
}

/* Landscape Style for Walkin21 */
.landscape {
  margin-top: 100px;
  width: 400px; /* Wider for landscape */
  height: 200px; /* Shorter height */
}

.landscape .sponsor-logo {
  max-width: 300px;
  height: auto;
}

.sponsor h3 {
  font-size: 1.5rem;
  color: #e2e2e6;
}

/* Responsive adjustments for smaller screens */

@media (max-width: 768px) {
  .sponsor-list {
    grid-template-columns: 1fr; /* Make each sponsor take the full width of the screen */
    gap: 30px; /* Add space between the sponsors */
  }

  .portrait {
    width: 50vw; /* Take 90% of the viewport width for better spacing */
    height: auto; /* Adjust height automatically */
  }

  .landscape {
    width: 60vw; /* Adjust width to fit the screen */
    height: auto; /* Adjust height automatically */
    margin-top: 0; /* Remove the margin for better spacing on mobile */
  }

  .sponsor-logo {
    max-width: 100%; /* Ensure the logo fits within the container */
    max-height: 150px; /* Constrain the height to prevent overflow */
    object-fit: contain; /* Ensure the image scales proportionally */
    height: auto; /* Ensure height adjusts automatically */
    width: auto; /* Ensure width adjusts automatically */
  }

  .sponsor h3 {
    font-size: 1.2rem; /* Adjust the font size for better readability */
  }
}


</style>
