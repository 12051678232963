<template>
 <div class="main-container">
    <HeaderComponent />
    <section id="interview" class="interview-section">
      <h2>Watch Our Crew Interviews!</h2>
      <div class="video-layout">
        <!-- Main Video -->
        <div class="main-video">
          <video ref="mainVideo" controls :style="videoStyles" preload="auto">
            <source :src="currentVideo" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div class="pad-Buttons">
        <!-- Video Selector Buttons -->
        <div class="other-videos">
          <button @click="changeVideo('https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/Interviews%2FElvin%20TS.mp4?alt=media&token=ced114a1-958c-4d82-8b32-8872960e0a60')" class="video-button">
            Elvin TS
          </button>
          <button @click="changeVideo('https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/Interviews%2FCodey%20%26%20Kariska%20TS.mp4?alt=media&token=926d2aa3-af48-42e1-af32-592a19889835')" class="video-button">
            Codey & Kariska TS
          </button>
          <button @click="changeVideo('https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/Interviews%2FActresses%20TS.mp4?alt=media&token=60356104-8213-4c40-ba26-4c6e8eafb1e0')" class="video-button">
            Accresses TS
          </button>
          <!-- Add more buttons for additional videos as needed -->
        </div>
        </div>
      </div>
      <br/>
      <br/>
    </section>
  </div>
  </template>
  
  <script>
  import Header from './Header.vue';
  export default {
  name: 'BTSPage',
  components: {
    HeaderComponent: Header,
  },
  data() {
    return {
      currentVideo: 'https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/Interviews%2FElvin%20TS.mp4?alt=media&token=ced114a1-958c-4d82-8b32-8872960e0a60', // Default video
      videoStyles: {
        width: '100%',
        height: '64.4vh', // Set a fixed height to ensure consistency
        backgroundColor: '#000', // Background color to avoid flash of white when video is loading
      },
    };
  },
  methods: {
    changeVideo(videoUrl) {
      this.currentVideo = videoUrl;
      this.$refs.mainVideo.load(); // Reload the video with the new source
    },
  },
};
</script>

<style scoped>
.main-container {
  min-height: 100vh;
  background: linear-gradient(to bottom, #2f2c33, #252328);
}
.interview-section {
  text-align: center;
  color: #e2d4b7;
  padding-left: 2.5%;
  padding-right: 5%;
}

.video-layout {
  display: grid;
  background-color: #2b282f;
  border-radius: 10px; /* Rounded corners for smoother design */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3); /* Add subtle shadow */
  padding: 1.5%;
  grid-template-columns: 3fr 1fr;
  gap: 30px;
  margin-top: 20px;
}

.main-video {
  background-color: #2b282f;
  padding: 20px;
  border-radius: 10px; 
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
}

.other-videos {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 70vh; /* Set a fixed height, adjust as needed */
  overflow-y: auto; /* Enable vertical scroll */
  overflow-x: hidden;
  margin-bottom: 2%;
}

.video-button {
  background-color: #2b282f;
  color: #e2e2e6;
  padding: 15px;
  font-size: 1.1rem; /* Increase font size for better readability */
  border-radius: 10px;
  cursor: pointer;
  text-align: left;
  transition: background-color 0.3s ease;
}

.video-button:hover {
  transform: scale(1.025);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
  color: #e2d4b7;
}

h2 {
  color: #e2e2e6;
  font-size: 36px; /* Increase the font size */
  margin-bottom: 30px;
}
/* Responsive Adjustments */
@media (max-width: 768px) {
  .video-layout {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .main-video {
    padding: 15px;
  }

  .video-button {
    font-size: 1rem;
    padding: 10px;
  }
  .pad-Buttons{
     padding-top: 5%;
     padding-bottom: 5%;
  }
}
  </style>
  