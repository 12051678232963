<template>
<div class="main-container">
    <HeaderComponent/>
    <div class="accolades-section">
          <h1>Watch this space...</h1>
          <h2>Good things coming soon!</h2>
        </div>
</div>
  </template>
  
  <script>
  import Header from './Header.vue';
  
  export default {
    name: 'AccoladesPage',
    components: {
      HeaderComponent: Header,
    },
    methods: {
      navigate(section) {
        const element = document.getElementById(section);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      },
    },
  };
  </script>
  <style scoped>
  .main-container {
  min-height: 100vh;
  overflow-y: hidden ;
    background-color: #3e1f25; /* Adjust background color if needed */
}
.accolades-section {
  text-align: center;
  padding: 25vh 5%; /* Adjust padding as needed */
}
.accolades-section h1,h2 {
    color: #e2e2e6; /* White text */
}
  
  </style>
  