  <template>
        <header>
          <div class="logo-container">
              <img src="@/assets/FMLogo.png" alt="AFDA Logo" class="logo" />
        <h1>Fata Morgana</h1>
        </div>
        <nav v-if="isDesktop">
          <ul>
            <li><router-link to="/">Home</router-link></li>
            <li><router-link to="/photos">Meet the Team</router-link></li>
            <li><router-link to="/bts">BTS</router-link></li>
            <li><router-link to="/interviews">Interviews</router-link></li>
            <li><router-link to="/accolades">Accolades</router-link></li>
            <li><router-link to="/sponsors">Sponsors</router-link></li>
            <li><router-link to="/photos">Contact Us</router-link></li>

          </ul>
        </nav>
        <div class="right-side">
        <div class="logo-container">
              <img src="@/assets/AFDA.png" alt="AFDA Logo" class="afda" />
        </div>
          
        <button class="menu-button" @click="toggleMenu">≡</button>
        <div v-if="isMenuOpen" class="popup-menu">
      <ul>
        <li><router-link to="/">Home</router-link></li>
        <li><router-link to="/photos">Meet the Team</router-link></li>
        <li><router-link to="/bts">BTS</router-link></li>
        <li><router-link to="/interviews">Interviews</router-link></li>
        <li><router-link to="/accolades">Accolades</router-link></li>
        <li><router-link to="/sponsors">Sponsors</router-link></li>
        <li><a href="/" @click.prevent="navigateHomeAndContact">Contact Us</a></li>
      </ul>
    </div>
      </div>
      </header>
    </template>
    
    <script>
    export default {
      name: 'HeaderComponent',
    data() {
      return {
        isMenuOpen: false, // State to control whether the menu is open
        isDesktop: true, // State to control whether the menu is open
      };
    },
      methods: {
        toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen; // Toggle the menu open state
      console.log(this.isMenuOpen);
    },
        navigate(section) {
          const element = document.getElementById(section);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        },
        navigateHomeAndContact() {
    // First navigate to the home page
    this.$router.push('/').then(() => {
      // After navigation, scroll to the contact section
      this.$nextTick(() => {
        const contactSection = document.querySelector('#contact');
        if (contactSection) {
          contactSection.scrollIntoView({ behavior: 'smooth' });
        }
      });
    });
  }
      },
      
    };
    </script>
    
    <style scoped>
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    background-color: #312b2b; /* Deep brown background */
  }

  /* Header Styles */
  header {
    display: flex; /* Use flexbox to align items horizontally */
    align-items: center; /* Align items vertically */
    justify-content: space-between; /* Space between title, nav, and button */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    color: #d4c59e;
    background: linear-gradient(to bottom, #2a2525, #312b2b); 
    width: 100%;
    background-repeat: no-repeat;

  }

  h1 {
    font-family: 'Bodoni', serif; /* Elegant serif font */
    font-size: 48px;
    margin: 0; /* Ensure no extra margin is added */
    flex: 1; /* Allow the title to take up available space */
  }

  /* Center the navigation */
  nav {
    flex: 2; /* Allow the nav to take up available space */
    display: flex;
    justify-content: center; /* Center the navigation items */
  }

  nav ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    gap: 30px;
    margin: 0;
  }

  nav ul li {
    display: inline;
  }

  nav ul li a {
    text-decoration: none;
    color: #e5e5e5; /* Soft off-white color */
    font-family: 'Bodoni', serif;
    font-size: 18px;
    padding: 0 20px; /* Increase space between links */
    display: inline-block;
    cursor: pointer;
  }

  nav ul li a:hover {
    color: #d4c59e; /* Highlight color on hover */
    text-decoration: underline;
    transition: color 0.4s ease;
  }

  /* Hamburger menu button */
  .menu-button {
    display: none;
    background: none;
    border: none;
    font-size: 18px; /* Size of the hamburger icon */
    color: #d4c59e;
    cursor: pointer;
  }
  .popup-menu {
  position: absolute;
  top: 60px;
  right: 10px;
  background-color: #312b2b;
  border: 1px solid #d4c59e;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.popup-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.popup-menu ul li a {
  color: #e5e5e5;
  font-family: 'Bodoni', serif;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
}

.popup-menu ul li a:hover {
  color: #d4c59e;
  text-decoration: underline;
  transition: color 0.4s ease;
}
  button {
    background: none;
    border: 2px solid #d4c59e; /* Soft golden border */
    padding: 10px 20px;
    color: #d4c59e; /* Soft golden text color */
    cursor: pointer;
    font-size: 1rem;
  }

  button:hover {
    background-color: #d4c59e; /* Soft golden background on hover */
    color: #3b291f; /* Deep brown text color on hover */
  }

  /* General Text Styling */
  h1 {
    color: #d4c59e; /* Soft golden color */
  }

  p {
    color: #e2d4b7; /* Light cream color */
  }

  .logo-container {
    display: flex;
    align-items: center;
    padding-right: 1vw;
  }

  .logo {
    height: 50px; /* Adjust the height to fit your design */
  }
  .afda {
    height: 30px; /* Adjust the height to fit your design */
  }
  /* Responsive Design for Mobile */
  @media (max-width: 768px) {
  header {
    flex-direction: row; /* Stack items vertically on mobile */

  }
  .menu-button {
    display: block;
    font-size: 28px;
  }

  h1 {
    font-size: 28px; /* Further reduce size for mobile */
    
    padding-right: 20vw;
  }
  .right-side{
    display: flex;
  }
  nav ul {
    gap: 1px; /* Reduce gap between links */
  }
  nav {
    display: none; /* Hide the navigation on mobile */
  }
  nav ul li a {
    font-size: 5px; /* Reduce font size for links */
  }

  .logo {
    height: 40px; /* Reduce logo size for mobile */
  }

  .afda {
    height: 25px; /* Reduce AFDA logo size for mobile */
  }
  nav[v-cloak] {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #312b2b;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    padding: 20px;
  }
  .popup-menu {
  position: absolute;
  top: 55px;
  right: 0px;
  background-color: #312b2b;
  border: 1px solid #d4c59e;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.popup-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.popup-menu ul li a {
  color: #e5e5e5;
  font-family: 'Bodoni', serif;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
}

.popup-menu ul li a:hover {
  color: #d4c59e;
  text-decoration: underline;
  transition: color 0.4s ease;
}

.menu-button {
  font-size: 28px;
  cursor: pointer;
}
}
  </style>
