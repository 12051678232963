<template>
    <!-- Header -->
    <HeaderComponent/>
    <!-- Image Section -->
    <section id="image-section" class="image-section">
      <div class="image-container">
        <img src="@/assets/FrontPage.avif" alt="Front page Image">
        <div class="poster">
          <a href="https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/frontpage%2FFataPoster.png?alt=media&token=85231b37-8c47-47a1-b584-c4bc58f002b5" target="_blank">
    <img src="https://firebasestorage.googleapis.com/v0/b/fatamorgana-8034a.appspot.com/o/frontpage%2FFataPoster.png?alt=media&token=85231b37-8c47-47a1-b584-c4bc58f002b5" alt="Poster" loading="lazy">
  </a>
        </div>
        <div class="overlay-text">
          <h1>Fata Morgana</h1>
          <h2>A Life Without You</h2>
          <div class="cta-container">
            <button class="cta-button">Coming soon</button>
          </div>
        </div>
      </div>
    </section>
<section id = "About" class = "about">
    <!-- Info Section -->
    <section id="Info" class="info-section">
      <h2>Who is Fata Morgana?</h2>
      <p>
        A dramatic magical realist narrative film that compares loss and love in two separate realities. Questioning what is real and what is not, who stayed and who got lost, and discovering what it means to love someone so deeply that your world does not look the same without them.
      </p>
    </section>


    <!-- Contact Us Section -->
    <section id="contact" class="contact-section">
      <h2>Contact Us</h2>
      <p>Email: <a href="mailto:example@email.com">fatamorgana24afda@email.com</a></p>
      <p>Instagram: <a href="https://www.instagram.com/fatamorgana_afda?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank">@fatamorgana_afda</a></p>
    </section>
  </section>
</template>

<script>
import Header from './Header.vue';

export default {
  name: 'MainPage',
  components: {
    HeaderComponent: Header,
  },
  methods: {
    navigate(section) {
      const element = document.getElementById(section);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
  computed:{
    isMobile() {
      return /Mobi|Android/i.test(navigator.userAgent);
    }
  }
};
</script>
<style scoped>
@keyframes fadeInZoom {
  0% {
    opacity: 0; /* Start invisible */
    transform: scale(1.1); /* Start slightly zoomed in */
  }
  100% {
    opacity: 1; /* End visible */
    transform: scale(1); /* Zoom to original size */
  }
}

.image-container {
  position: relative;
  width: 100%;
  height: 93vh; /* Make the container fill the full viewport height */
  overflow: hidden; /* Ensure the image stays within the container */
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the container without stretching */
  animation: fadeInZoom 1.5s ease-in-out;
}

.overlay-text {
  position: absolute;
  top: 25%; /* Keep text at the original place */
  right: 10%; /* Align to the right */
  transform: none;
  text-align: right; /* Ensure text aligns to the right */
}
.poster{
  position: absolute;
  top:15%; /* Keep text at the original place */
  left: 15%;
  transform: none;
  text-align: left; /* Ensure text aligns to the right */
}
.poster img{
  width: 30%;
  height: 30%;
  object-fit: cover; /* Ensure the image covers the container without stretching */
  border: 1px solid #d4c59e;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4); /* Subtle shadow effect */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add transition for smooth scaling */
}

.poster img:hover {
  transform: scale(1.05); /* Grows the image by 10% when hovering */
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.6); /* Increase shadow for effect */
}
.overlay-text h1 {
  font-family: 'Bodoni', serif;
  font-size: 60px; /* Larger font size for the title */
  font-weight: bold;
  margin: 0;
  
}

.overlay-text h2 {
  font-family: 'Bodoni', serif;
  font-size: 28px; /* Smaller font size for the subtitle */
  font-weight: normal;
  margin: 30px 0 40px 0; /* Add margin to separate it from the button container */
}
.overlay-text h1, .overlay-text h2 {

  color: #d4c59e;
  text-shadow: 
    1px 1px 0px #2a2625,  /* Right-bottom shadow */
    -1px 1px 0px #2a2625, /* Left-bottom shadow */
    1px -1px 0px #2a2625, /* Right-top shadow */
    -1px -1px 0px #2a2625; /* Left-top shadow */

}

/* CTA Button Styles */
.cta-container {
  margin-top: 100px; /* Add some space between the text and button */
  
}

.cta-button {
  background-color: #d4c59e; /* Initial background color */
  border: none;
  padding: 15px 30px;
  color: #3b291f; /* Initial text color */
  cursor: pointer;
  font-size: 1.2rem;
  font-family: 'Bodoni', serif; /* Elegant serif font */
  border-radius: 5px;
  text-transform: uppercase;
  transition: all 0.3s ease; /* Smooth transition */
  
}

.cta-button:hover {
  background-color: #e5e5e5;
  color: #312b2b; /* Adjust text color for contrast */
  transform: scale(1.05); /* Slightly increase size on hover */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Add a subtle shadow */
}

/* Section Styles */
.info-section{
  padding: 50px 20px;
  text-align: center;
  color: #e2d4b7; /* Light cream text color */
}
.info-section p{
  max-width: 1200px;
  margin: 0 auto; /* This centers the paragraph */
  text-align: center; /* Optional: This centers the text inside the paragraph */
}
.about {
  background: linear-gradient(to bottom, #2d3526, #151a10);
  animation: none; /* Disable any animation */
  transition: none; /* Disable any transitions */
}
p {
  color: #e2d4b7; /* Light cream color */
}

.contact-section {
  color: #d4c59e; /* Soft golden text color */
  text-align: center;
  padding: 50px;
}

.contact-section a {
  color: #d4c59e; /* Link color */
  text-decoration: none;
}

.contact-section a:hover {
  color: #e5e5e5; /* Lighter color on hover */
  text-decoration: underline;
}

@media (max-width: 768px) {
  /* Adjust the image section to fit mobile screens */
  .image-container {
    height: 70vh; /* Reduce height on mobile for better view */
  }

  .overlay-text {
    top: 50%; /* Move text up on mobile */
    right: 2%; /* Reduce right padding on mobile */
    text-align: center; /* Center text on mobile */
    width: 100%; /* Ensure full width for mobile */
    outline: #d4c59e;
  }

  .overlay-text h1 {
    font-size: 40px; /* Reduce title size on mobile */
  }

  .overlay-text h2 {
    font-size: 20px; /* Reduce subtitle size */
    margin: 15px 0 20px 0; /* Reduce margin */
  }
  .poster{
  position: absolute;
  top:5%; /* Keep text at the original place */
  left: 30%;
  transform: none;
  text-align: left; /* Ensure text aligns to the right */
}
.cta-container{
  margin-top: 50px;
}
.poster img{
  width: 55%;
  height: 55%;
  object-fit: cover; /* Ensure the image covers the container without stretching */
  border: 1px solid #d4c59e;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4); /* Subtle shadow effect */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add transition for smooth scaling */
}

.poster img:hover {
  transform: scale(1.05); /* Grows the image by 10% when hovering */
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.6); /* Increase shadow for effect */
}

  .cta-button {
    font-size: 1rem; /* Reduce button size on mobile */
    padding: 12px 20px;
  }

  /* Info Section */
  .info-section {
    padding: 30px 10px; /* Reduce padding for mobile */
  }

  /* Contact Section */
  .contact-section {
    padding: 30px 10px; /* Reduce padding for mobile */
  }
}
</style>
