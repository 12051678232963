import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/components/MainPage.vue';
import BTS from '@/components/BTS.vue';
import Photos from '@/components/Photos.vue';
import Interviews from '@/components/Interview.vue';
import Sponsors from '@/components/Sponsors.vue';
import Accolades from '@/components/AccoladesPage.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/bts',
    name: 'BTS',
    component: BTS
  },
  {
    path: '/photos',
    name: 'Photos',
    component: Photos
  },
  {
    path: '/interviews',
    name: 'Interviews',
    component: Interviews
  },
  {
    path: '/accolades',
    name: 'Accolades',
    component: Accolades
  },
  {
    path: '/Sponsors',
    name: 'Sponsors',
    component: Sponsors
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
